<script setup>
import DropdownLang from "@/components/AppDropdownLang.vue";
import { RouterLink } from 'vue-router';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Bars3Icon, BellAlertIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { useLocalizedPath } from "@/use/useLocalizedPath";
import AppNotificationHeader from "./AppNotificationHeader.vue";

const { getLocalizedPath } = useLocalizedPath();
const store = useStore();
const isAuth = computed(() => store.getters['account/isAuthenticated'])
const service = computed(() => store.getters['service/service'])

const notifToggle = ref(false)
const notifications = computed(() => store.getters['notifications/notifications'])
const openNotif = async () => {
  await store.dispatch('notifications/fetchNotifications')
  notifToggle.value = !notifToggle.value
}
</script>

<template>
  <header class="header pb-2 lg:py-3">
    <Disclosure as="nav" v-slot="{ open, close }">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 items-center justify-between">

          <div class="logo">
            <RouterLink :to="getLocalizedPath('/')">
              <img class="w-28 sm:w-32" src="@/assets/images/logo-jet.svg" alt="">
            </RouterLink>
          </div>

          <div v-if="isAuth" class="hidden lg:ml-6 lg:block">
            <div class="flex space-x-4">
              <RouterLink
                :to="getLocalizedPath('/')" 
                class="rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-200 hover:text-black" 
                active-class="bg-green-jet text-white hover:bg-green-jet hover:text-white"
              >
                {{ $t('nav_home') }}
              </RouterLink>
              <RouterLink
                v-if="service?.inet_service_id"
                :to="getLocalizedPath(`/service/${service.inet_service_id}`)" 
                class="rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-200 hover:text-black" 
                active-class="bg-green-jet text-white hover:bg-green-jet hover:text-white"
              >
                {{ $t('nav_tariffs') }}
              </RouterLink>
              <RouterLink
                :to="getLocalizedPath('/payment')" 
                class="rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-200 hover:text-black" 
                active-class="bg-green-jet text-white hover:bg-green-jet hover:text-white"
              >
                {{ $t('nav_payment') }}
              </RouterLink>
              <RouterLink
                :to="getLocalizedPath('/settings')" 
                class="rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-200 hover:text-black" 
                active-class="bg-green-jet text-white hover:bg-green-jet hover:text-white"
              >
                {{ $t('nav_settings') }}
              </RouterLink>
            </div>
          </div>
  
          <div class="flex gap-3 sm:flex-nowrap sm:flex-row items-center">
            <div
              v-if="!isAuth" 
              class="sm:mr-5"
            >
              <a href="tel:+998781500000" class="text-black font-bold text-sm hover:text-black/75">+998 78 150 00 00</a>
            </div>
            
            <button
              v-if="isAuth"
              type="button"
              class="p-1" 
              @click="openNotif"
            >
              <BellAlertIcon class="w-5 h-5" />
            </button>
            
            <DropdownLang />
            
            <div v-if="isAuth" class="-mr-2 flex lg:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton 
                class="relative inline-flex items-center justify-center rounded-md p-2 text-green-jet"
                :class="[{'hover:bg-green-jet hover:text-white focus:outline-none': open}]"
              >
                <span class="absolute -inset-0.5" />
                <span class="sr-only">{{ $t('menu_toggle')  }}</span>
                <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
              </DisclosureButton>
            </div>
          </div>
         
        </div>
      </div>

      <DisclosurePanel v-if="isAuth" class="lg:hidden">
        <div class="space-y-1 px-2 pb-3 pt-2">
          <RouterLink
            :to="getLocalizedPath('/')" 
            @click="close"
            class="block rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-200 hover:text-black" 
            active-class="bg-green-jet text-white hover:bg-green-jet hover:text-white"
          >
            {{ $t('nav_home') }}
          </RouterLink>
          <RouterLink
            v-if="service?.inet_service_id"
            :to="getLocalizedPath(`/service/${service.inet_service_id}`)" 
            @click="close"
            class="block rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-200 hover:text-black" 
            active-class="bg-green-jet text-white hover:bg-green-jet hover:text-white"
          >
            {{ $t('nav_tariffs') }}
          </RouterLink>
          <RouterLink
            :to="getLocalizedPath('/payment')" 
            @click="close"
            class="block rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-200 hover:text-black" 
            active-class="bg-green-jet text-white hover:bg-green-jet hover:text-white"
          >
            {{ $t('nav_payment') }}
          </RouterLink>
          <RouterLink
            :to="getLocalizedPath('/settings')" 
            @click="close"
            class="block rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-200 hover:text-black" 
            active-class="bg-green-jet text-white hover:bg-green-jet hover:text-white"
          >
            {{ $t('nav_settings') }}
          </RouterLink>
        </div>
        
      </DisclosurePanel>
    </Disclosure>

    <AppNotificationHeader
      :show="notifToggle"
      :notifications="notifications"
      @setVisibility="notifToggle = !notifToggle"
    />

  </header>
</template>

