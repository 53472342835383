import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import VCalendar from 'v-calendar'
import '@/assets/tailwind.css';
import '@/assets/fonts.scss';
import 'v-calendar/style.css';
import '@/assets/app.scss';


const app = createApp(App);

app
  .use(i18n)
  .use(VCalendar)
  .use(store)
  .use(router)

router.isReady().then(() => app.mount('#app'))
