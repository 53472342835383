<script setup>
import { XMarkIcon } from '@heroicons/vue/20/solid'
import AppNotificationList from "@/components/AppNotificationList.vue";
const props = defineProps({
  show: Boolean,
  notifications: Array
})
const emit = defineEmits(['setVisibility'])
</script>


<template>
  <div 
    aria-live="assertive" 
    class="pointer-events-none fixed inset-0 flex flex-col px-4 py-6 justify-start sm:p-6 z-20"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end grow">
      <transition 
        enter-active-class="transform ease-out duration-300 transition" 
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" 
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" 
        leave-active-class="transition ease-in duration-100" 
        leave-from-class="opacity-100" 
        leave-to-class="opacity-0"
      >
        <div 
          v-if="show" 
          class="relative pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 max-h-[450px]"
        >
          <button 
            type="button" 
            @click="emit('setVisibility')" 
            class="absolute top-3 right-3 inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus-visible:outline-none"
          >
            <span class="sr-only">Close</span>
            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <div class="p-4 pt-10 pr-3 h-full">
            <div class="overflow-y-auto overflow-x-hidden h-full">
              <div class="flex items-start pr-3">
                <div class="grow shrink">
                  <AppNotificationList 
                    v-if="notifications" 
                    :notifications="notifications"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

