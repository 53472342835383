import { createRouter, createWebHistory } from 'vue-router'
import Cookies from 'js-cookie';
import store from '@/store'
import i18n from "@/plugins/i18n";
import languages from '@/utils/languages';
import { nextTick } from 'vue';
const supportedLanguages = languages.map(lang => lang.locale)
const lang = "/:lang(en|uz)";
const regex = /^\/(uz|en)(\/|$)/;

const baseRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      layout: 'LayoutDefault',
      auth: true,
      title: 'title_home'
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      layout: 'LayoutDefault',
      title: 'title_login',
      auth: false,
    },
  },

  {
    path: '/access/:id',
    name: 'Access',
    component: () => import('../views/TrustedAccessView.vue'),
    meta: {
      auth: true,
      layout: 'LayoutDefault',
      title: 'title_access'
    },
  },
  
  {
    path: '/loyalty/:id',
    name: 'loyalty',
    component: () => import('../views/LoyaltyView.vue'),
    meta: {
      auth: true,
      layout: 'LayoutDefault',
      title: 'title_loyalty'
    },
  },
  
  {
    path: '/loyalty-offer',
    name: 'loyalty-offer',
    component: () => import('../views/LoyaltyOfferView.vue'),
    meta: {
      auth: true,
      layout: 'LayoutDefault',
      title: 'title_loyalty_offer'
    },
  },

  {
    path: '/service/:id',
    name: 'Service',
    component: () => import('../views/ServiceView.vue'),
    meta: {
      auth: true,
      layout: 'LayoutDefault',
      title: 'title_tariffs'
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/PaymentView.vue'),
    meta: {
      auth: true,
      layout: 'LayoutDefault',
      title: 'title_payment'
    },
  },

  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/SettingsView.vue'),
    meta: {
      auth: true,
      layout: 'LayoutDefault',
      title: 'title_profile'
    },
  },

  { 
    path: '/:pathMatch(.*)*',
    name: 'ErrorView404',
    component: () => import('../views/ErrorView404.vue'),
    meta: {
      layout: 'LayoutDefault',
      title: 'title_404',
      error: true
    },
  },


]


const routes = [...baseRoutes];

baseRoutes.forEach(route => {
  routes.push({
    path: `${lang}${route.path === '/' ? '' : route.path}`,
    component: route.component,
    meta: route.meta
  });
});


const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const cookieLocale = Cookies.get('locale')
  const browserLocale = navigator.language.split('-')[0];
  const confirmedBrowserLocale = supportedLanguages.includes(browserLocale) ? browserLocale : null
  const locale = cookieLocale || to.params.lang || confirmedBrowserLocale || 'uz'
  // console.log("locale from url: ", to.params.lang);
  // console.log("locale from cookie: ", cookieLocale);
  // console.log("locale browser: ", browserLocale);
  // console.log("supported locales: ", supportedLanguages);
  // console.log("confirmed locale browser: ", confirmedBrowserLocale);
  // console.log("setted locale: ", locale);
  
  i18n.global.locale.value = locale
  store.commit('setLocale', locale)

  const requireAuth = to.meta.auth
  const isAuthenticated = store.getters['account/isAuthenticated'];
  const langIsRussian = locale === 'ru'
  const currentLocalePath = langIsRussian ? '' : `/${locale}`
  const is404 = to.meta.error
  const path = to.path.replace(regex, '/')
  
  if (requireAuth && isAuthenticated) {
    // console.log('Required auth and authenticated');
    if (!langIsRussian && !to.path.startsWith(currentLocalePath)) {
      next(`${currentLocalePath}${path}`);
    } else {
      next();
    }
  } else if (requireAuth && !isAuthenticated) {
    // console.log('Required auth and not authenticated');
    store.commit('setRedirectPath', path)
    next(`${currentLocalePath}/login`)
  } else if( !requireAuth && store.getters['account/isAuthenticated'] ) {
    // console.log('Not required auth and authenticated');
    if (!langIsRussian && !to.path.startsWith(currentLocalePath)) {
      if( is404 ) {
        next(`${currentLocalePath}${path}`);
      } else {
        next(`${currentLocalePath}/`);
      }
    } else {
      next();
    }
  } else {
    // console.log('Others');
    next()
  }
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0)
  nextTick(() => {
    const title = i18n.global.t(to.meta.title);
    document.title = title || '';
  });
})

export default router
