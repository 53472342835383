import { createI18n } from 'vue-i18n';
import languages from "@/utils/languages";

const messages = languages.reduce((obj, lang) => {
  obj[lang.locale] = lang.file;
  return obj;
}, {});

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'ru', // Язык по умолчанию
  fallbackLocale: 'uz', // Запасной язык
  messages,
});

export default i18n;