import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state() {
    return {
      tariffs: null,
      tariffsJet: null,
      tariffsTurbo: null,
      additionalServices: null,
    }
  },
  mutations: {
    setTariffs(state, tariffs) {
      state.tariffs = tariffs
    },
    setTariffsJet(state, tariffs) {
      state.tariffsJet = tariffs
    },
    setTariffsTurbo(state, tariffs) {
      state.tariffsTurbo = tariffs
    },
    setAdditionalServices(state, additionalServices) {
      state.additionalServices = additionalServices
    }
  },
  actions: {
    async fetchTariffs({commit, dispatch, rootGetters}, id) {
      try {
        const {data} = await axios.get(`/api/v1/tariffs/list/${id}`)

        if (data?.data?.o_Result === '1') {
          commit('setTariffs', data?.data?.row || null)
        } else {
          throw new Error(data?.data?.o_Out_Text || 'unknown error');
        }

      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async fetchTariffsTurbo({commit, dispatch}, id) {
      try {
        const {data} = await axios.get(`/api/v1/loyalty/jet-buttons/${id}`)

        if (data?.data?.o_Result === '1') {
          commit('setTariffsTurbo', data?.data?.row || null)
        } else {
          throw new Error(data?.data?.o_Out_Text || 'unknown error');
        }


      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async fetchTariffsJet({commit, dispatch}, id) {
      try {
        const {data} = await axios.get(`/api/v1/loyalty/jet-tariffs/${id}`)

        if (data?.data?.o_Result === '1') {
          commit('setTariffsJet', data?.data?.row || null)
        } else {
          throw new Error(data?.data?.o_Out_Text || 'unknown error');
        }


      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async fetchAdditionalServices({commit, dispatch}, id) {
      try {
        const {data} = await axios.get(`/api/v1/hophop/packages/list/${id}`)
        commit('setAdditionalServices', data?.data || null)

      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async changeTariff({dispatch}, {inetServiceId, newTariffId}) {
      try {
        const {data} = await axios.post(`/api/v1/tariffs/change/${inetServiceId}`, {
          new_tariff_id : newTariffId
        })
        dispatch('setMessage', {
          value: data?.data?.o_Out_Text || 'unkown',
          type: data?.data?.o_Result === '1' ? 'success' : 'error'
        }, {root: true})

        return {status: data?.response?.[0]?.o_Result}

      } catch (e) {
        console.log(e)
        dispatch('setMessage', {
          value: e?.response?.data?.message,
          type: 'error'
        }, {root: true})
      }
    },
    async buyAdditionalService({dispatch}, {inetServiceId, serviceId}) {
      try {
        const {data} = await axios.post(`/api/v1/hophop/packages/buy/`, {
            inet_service_id: inetServiceId,
            package_id: serviceId
        })

        dispatch('setMessage', {
          value: data?.message || 'Услуга успешно подключена',
          type: data?.status === 'success' ? 'success' : 'error'
        }, {root: true})
        window.location.reload();
        return {status: data?.o_Result}

      } catch (e) {
        dispatch('setMessage', {
          value: e.response.data.message,
          type: 'error'
        }, {root: true})
      }
    },
    async enableTurbo({dispatch}, {inetServiceId, newTariffId}) {
      try {
        const {data} = await axios.post(`/api/loyalty/turbo-button/${inetServiceId}`, {
          packageType: newTariffId
        })

        dispatch('setMessage', {
          value: data?.response?.o_Out_Text || 'unkown',
          type: data?.response?.o_Result === '1' ? 'success' : 'error'
        }, {root: true})


      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async fetchUserHopHopId({commit, dispatch}) { // Add fetchUserHopHopId action
      try {
        const {data} = await axios.get('/api/v1/hophop/packages/get-id');
        if (data?.status === 'success') {
          localStorage.setItem('user_hop_hop_id', data?.data?.user_hop_hop_id || null);
        } else {
          throw new Error(data?.message || 'Failed to get user hop hop id');
        }
      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true});
      }
    },
  },
  getters: {
    tariffs(state) {
      return state.tariffs
    },
    tariffsJet(state) {
      return state.tariffsJet
    },
    tariffsTurbo(state) {
      return state.tariffsTurbo
    },
    additionalServices(state) {
      return state.additionalServices
    }
  }
}
