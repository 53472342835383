import axios from 'axios'
import i18n from "@/plugins/i18n";
import router from "@/router";
import store from '@/store';
import Cookie from "js-cookie";

const locale = i18n.global.locale.value;

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

axiosInstance.interceptors.request.use(request => {
    const token = store.getters['account/token'] || Cookie.get('token') || null;
    const locale = store.getters['locale'];
    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`
      request.headers['Accept-Language'] = locale
    }

    return request
  },
  function (error) {
    return Promise.reject(error)
  }
)


axiosInstance.interceptors.response.use(null, async (error) => {
    const originalRequest = error.config;
    const refreshToken = store.getters['account/refreshToken'] || Cookie.get('refreshToken');
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
      try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/auth/refresh-token`, { refresh_token: refreshToken });
          const access_token = response?.data?.data?.access_token;
          store.commit('account/setToken', access_token, {root: true})
          Cookie.set('token', access_token, { expires: 366, secure: true, sameSite: 'Strict' });
          originalRequest.headers['authorization'] = `Bearer ${access_token}`;
          return axiosInstance(originalRequest);
      } catch {
          const currentLocale = locale === 'ru' ? '' : `/${locale}`
          store.commit('account/setToken', null, {root: true})
          Cookie.remove('token')
          router.push(`${currentLocale}/login`)
      }
  }
  return Promise.reject(error)
})

export default axiosInstance
