import axios from "@/plugins/axios";
import Cookie from "js-cookie";

export default {
  namespaced: true,
  state() {
    return {
      token: Cookie.get('token'),
      abonent: null,
      auth: null,
      captcha: null,
      subjectId: null,
      hophopId: null,
      refreshToken: null
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      Cookie.set('token', token, { expires: 366, secure: true, sameSite: 'Strict' });
    },
    setRefreshToken(state, token) {
        state.refreshToken = token;
        Cookie.set('refreshToken', token, { expires: 366, secure: true, sameSite: 'Strict' });
    },
    setAbonent(state, data) {
      state.abonent = data
    },
    setAuth(state, data) {
      state.auth = data
    },
    setCaptcha(state, data) {
      state.captcha = data
    },
    logout(state) {
      state.token = null
      Cookie.remove('token')
      // localStorage.removeItem(TOKEN_KEY)
    },
    setSubjectId(state, data) {
        state.subjectId = data
    },
    setHopHopId(state, data) {
      state.hophopId = data
    }
  },
  actions: {
    async login({ commit, dispatch, rootGetters }, payload) {
        try {
        // const payload = {login: 'tps2455106', password: 'tps24551060'}
        // const payload = {login: 'tpsfttb', password: 'tpsfttb'}
        // const payload = {login: 'tpsfttb2', password: 'tpsfttb2'}
        // const payload = {login: 'tpsadsl', password: 'tpsadsl'}
        const locale = rootGetters['locale'];
        const {data} = await axios.post('/api/v1/auth/login', {...payload}, {
          headers: {
            'Accept-Language': locale
          }
        })
        if( data?.status === "success" ) {
          commit('setToken', data?.data?.access_token || null)
          commit('setRefreshToken', data?.data?.refresh_token || null)
          // commit('setAbonent', data?.data?.info?.abonent || null)
          // commit('setAuth', data?.data?.authResponse || null)
          // commit('contract/setContract', data?.data?.info?.contracts_info?.contract || null, { root: true })
          // commit('service/setService', data?.data?.info?.contracts_info?.contract?.services?.service || null, { root: true })
          // commit('jetPoints/setTariffsTurbo', data?.data?.jetBalance?.accounts?.account || null, { root: true })
        } else {
          throw new Error(data?.data?.o_Out_Text || data?.message);
        }
      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async fetchProfile({ commit, dispatch, rootGetters }, payload) {
      try {
        // const payload = {login: 'tps2455106', password: 'tps24551060'}
        // const payload = {login: 'tpsfttb', password: 'tpsfttb'}
        // const payload = {login: 'tpsfttb2', password: 'tpsfttb2'}
        // const payload = {login: 'tpsadsl', password: 'tpsadsl'}
        const locale = rootGetters['locale'];
        const {data} = await axios.get('/api/v1/profile', {
          headers: {
            'Accept-Language': locale
          }
        })
        if( data?.status === "success" ) {
          commit('setSubjectId', data?.data?.subject_id || null)
          commit('setAbonent', data?.data?.info?.abonent || null)
          commit('contract/setContract', data?.data?.info?.contracts_info?.contract || null, { root: true })
          commit('service/setService', data?.data?.info?.contracts_info?.contract?.services?.service || null, { root: true })
          commit('jetPoints/setTariffsTurbo', data?.data?.jetBalance?.accounts?.account || null, { root: true })
          commit('setHopHopId', data?.data?.user_hop_hop_id || null);
          localStorage.setItem('user_hop_hop_id', data?.data?.user_hop_hop_id || null);
        } else {
          throw new Error(data?.data?.o_Out_Text || data?.message);
        }
      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async logout({ commit, dispatch }) {
      try {
        const {data} = await axios.post('/api/logout')
        commit('logout')
      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async fetchCaptcha({ commit, dispatch }) {
      try {
        const {data} = await axios.get('/captcha/api/default')
        commit('setCaptcha', data);

      } catch (e) {
        dispatch('setMessage', {
          value: e?.response?.data?.message || e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async changePassword({ commit, dispatch }, { form_data }) {
      const {oldPassword, newPassword, repeatNewPassword, ...rest} = form_data;
      try {
        const {data} = await axios.post(`/api/v1/password/change/`, {old_password: oldPassword, new_password: newPassword, repeat_new_password: repeatNewPassword, ...rest})
        if( data?.data?.o_Result === "1" ) {
          dispatch('logout');
          dispatch('setMessage', {
            value: data?.data?.message,
            type: 'success'
          }, {root: true})
          return { status: data?.data?.o_Result }
        } else {
          throw new Error(data?.data?.message || data?.message);
        }
      } catch (e) {
        dispatch('fetchCaptcha')
        dispatch('setMessage', {
          value: e?.response?.data?.message || e.message,
          type: 'error'
        }, {root: true})
      }
    },
  },
  getters: {
    token(state) {
      return state.token
    },
    refreshToken(state) {
        return state.refreshToken;
    },
    abonent(state) {
      return state.abonent
    },
    auth(state) {
      return state.auth
    },
    captcha(state) {
      return state.captcha
    },
    isAuthenticated(_, getters) {
      return !!getters.token
    },
    subjectId(state) {
      return state.subjectId
    },
    hophopId(state) {
      return state.hophopId;
    }
  }
}
