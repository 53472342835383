import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state() {
    return {
      service: null,
    }
  },
  mutations: {
    setService(state, service) {
      state.service = service
    },
    clearService(state) {
      state.service = null
    },
  },
  actions: {
    async enableTrustedAccess({commit, dispatch}, id) {
      try {
        const { data } = await axios.post('/api/v1/access-request/', { service_id: id })
        if( data?.data?.o_Result === '1' ) {
          dispatch('setMessage', {
            value: data?.data?.o_Out_Text,
            type: 'success'
          }, {root: true})
        } else {
          throw new Error(data?.data?.o_Out_Text);
        }
      } catch(e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    }
  },
  getters: {
    service(state) {
      return state.service
    },
  }
}