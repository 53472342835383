
<script setup>
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import LayoutDefault from './layouts/LayoutDefault.vue'


const route = useRoute()
const layout = computed(() => {
  const layouts = {
    LayoutDefault,
  }
  return layouts[route.meta.layout];
})

</script>

<template>
  <component :is="layout" v-if="layout"></component> 
</template>

